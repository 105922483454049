ul{
	list-style: none;
}
.hayhar-page .m2-001,.m2-002,.m2-003,.m2-004,.m2-005,.m2-006{
	.text-top{
		display: none !important;
	}
	.ant-carousel{
		.slick-list{
			.slick-track{
				display: flex;
			}
			.img_wrap{
				img{
					object-fit:initial !important;
				}
			}
		}

	}

}

.m2-006 .central-web.central-web-2830 .central-box{margin-top:5.6%}
.hayhar-page,.m2-001,.m2-003,.m2-004,.m2-005,.m2-006{
	.ant-carousel{
		height: 730px;
		.slick-list{
			.slick-track{
				display: flex;
			}
			.img_wrap{
				img{
					object-fit:initial !important;
				}
			}
		}

	}
	// .ant-carousel{
	// 	height: 730px;
	// }
	position: relative;
	.img_wrap{
		img{
			object-fit:cover;
			height: 100%;
			width: 100%;
			vertical-align: middle;
		}
		height: 730px;
	}
	.mobile{
		display:none;

		&.top{
			display: none;
		}

	}
	overflow: hidden;
	.top.web{
		input{
			height: 2rem;
		}
	}
	input{
		height: 2.65rem;
		border-radius:.25rem;
		padding: .25rem .3rem;
		box-sizing: border-box;
		outline-style:none;
		outline-width: 0px;
		border:2px solid transparent;
		text-shadow: none;
		-webkit-appearance:none;
		-webkit-user-select:text;
		outline-color: transparent;
		box-shadow: none;
		text-overflow:ellipsis;
	}
	.top{
		padding: 1em;
		background-color: #0EA5E9;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.qrp-header-logo{
			width: 150px;
		}

		// 输入框
		.web-input-box{
			position: relative;
			display: flex;
			align-items: center;
			.web-input{

				&:focus{
					box-shadow: 0 0, #93c5fd;
					border-color:#93c5fd;

				}
			}
			.web-input+.web-input{
				margin-left: .5rem;
				width: 15rem;
				padding-right: 5rem;
				// margin-right: 5rem;
			}
			.web-label{
				position: absolute;
				top: .5rem;
				right:9.6rem;
				color: inherit;
				text-decoration:inherit;
				font-size: .75rem;
				line-height: 1rem;
				cursor: pointer;
				padding-left: .3rem;
				border-left: 1px solid rgba(229,231,235,1);
				&:hover{
					color: rgba(56,189,248,1);
				}
			}

			// 登录按钮
			.web-login{
				padding: 0.25rem 1rem;
				margin-left: .3rem;
				background-color: #FFF;
				border:none;
				height: 2rem;
				border-radius: .25rem;
				border: 1px solid transparent;
				&:hover{
					border-color: #FFF;
					background-color: #0EA5E9;
					color: #FFF;
				}
			}
			.country-icon{
				margin-left: .6rem;
				border-left: 1px solid #FFF;
				padding-left: .6rem;
			}

		}
	}


	.ant-input{
		color: #000;
	}

	.error-hit{
		display: flex;
		margin-bottom: 1rem;
		p{
			color: #ff0000;
			width: 50%;
			text-align: left;
		}
	}

	// 中间内容
	.central-web{
		// background: url(/static/images/hayhar/QRP-NUFC-Desktop.jpg) no-repeat center ;
		// background-size:100%;
		overflow: hidden;
		// position: relative;
		.text-top{
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: .8rem auto;
			position: absolute;
			top: 4.55rem;
			left: 30%;
			h2{
				font-size: 3rem;
			}
			p{
				font-size: 2rem;
			}
		}
		.central-box{
			// position: absolute;
			border-radius: .5rem;
			overflow: hidden;
			background-color: #FFF;
			margin: 5% 0 0 56%;
			width: 27rem;
			position: absolute;
			top: 12rem;
			.tips{
				color:#9ca3af ;
				margin: .75rem auto 0;
				text-align: center;

			}
			.heading{
				padding:1rem 0;
				background-color: #0EA5E9;
				text-align: center;
				color: #FFF;
			}
			.form-modal{
				padding: 1.25rem;
				padding-bottom: 0;
				input{
					border: 1px solid rgb(209,213,219);
					-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(209,213,219 .6);
					box-shadow: inset 0px 1px 1px rgba(0, 0, 0, .075) , 0px 0px 8px rgba(209,213,219 .6);
					&:focus{
						border:2px solid #0EA5E9;
					}
				}
				.namePass{
					display: flex;
					justify-content: space-between;
					margin-bottom: 1rem;
					input{
						width: 49%;
					}
				}
				.error-hit{
					display: flex;
					margin-bottom: 1rem;
					p{
						color: #ff0000;
						width: 50%;
						text-align: left;
					}
				}
				.email-input{
					display: flex;
					margin-bottom: 1rem;
					input{
						width: 100%;
					}

				}
				.phone-number{
					display: flex;
					justify-content: space-between;
					input.phone{
						width: 20%;
						color: #9ca3af;
						cursor: no-drop;
					}
					input.phoneNumber{
						width: 78%;
					}
				}
			}
			.submit{
				margin: 1rem auto;
				padding: 8px 16px;
				display: flex;
				justify-content: center;
				background-color: #0ea5e9;
				border: none;
				border-radius: .25rem;
				color: #FFF;
				font-size: .95rem;
				&:hover{
					background-color: rgb(56,189,248);
				}
			}
		}
	}


	// 底部内容
	.footer-content-web{

		.partner{
			padding: 1rem;
			background-color: rgb(209,213,219,);
			display: flex;
			flex-wrap: wrap;
			.item{
				width: 33.333333%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.title{
					margin-bottom: 1.25rem;
				}
				.image-box{
					display: flex;
					justify-content: space-between;
					& > div{
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						& +div{
							margin-left: 10px;
						}
					}

					& .default{
						height: 5rem;
						width: 3rem;
					}
					.footer-image-box-text{
						display: flex;
						flex-direction: column;
						font-size: .75rem;
						text-align: center;
						a{
							color: #000;
						}
					}
					.partner-item-0{
						background: url('/static/images/hayhar/tottenham.png') no-repeat center;
						background-size: cover;
						&:hover{
							background: url('/static/images/hayhar/tottenham-hover.png') no-repeat center;
							background-size: cover;
						}
					}
					.parrtner-item-1{
						background: url('/static/images/hayhar/newcastle.png') no-repeat center;
						background-size: cover;
						&:hover{
							background: url('/static/images/hayhar/newcastle-hover.png') no-repeat center;
							background-size: cover;
						}
					}

				}
			}
			// 品牌形象大使
			.brand {
				.brand-default{
					width: 16.25rem;
					height: 9.375rem;
				}
				.brand-item-0 {
					background: url(/static/images/hayhar/Iker.png) no-repeat center center;
					background-size: cover;
					&:hover{
						background: url(/static/images/hayhar/Iker-hover.png) no-repeat center center;
						background-size: cover;
					}
				}
			}
			// 供应商
			.suppliers{
				.suppliers-public-style{
					margin-top:calc(1rem*(1 - 0));
					margin-right: calc(0.75rem*0);
					margin-left: calc(0.75rem*(1 - 0));
				}
				.suppliers-box{
					display: flex;
					justify-content: center;
					flex-wrap:wrap;
				}
				.suppliers-default{
					width: 4rem;
					height: 2.25rem;
				}
				.suppliers-item-0{
					margin-top: 1rem;
					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -6.18rem -47px;
					background-size: 383px;

					// background: url('/static/images/hayhar/AG_Unhover.png')no-repeat center;


					&:hover{
						background: url('/static/images/hayhar/AG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-1{
					// background: url('/static/images/hayhar/BTI_Unhover.png')no-repeat center;
					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -13.3rem -259px;
    				background-size: 574px;
					&:hover{
						background: url('/static/images/hayhar/BTI_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-2{
					// background: url('/static/images/hayhar/CMD_Unhover.png')no-repeat center;
					// background-size: cover;
					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -22.5rem -3px;
    				background-size: 561px;
					&:hover{
						background: url('/static/images/hayhar/CMD_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-3{
					// background: url('/static/images/hayhar/eBet_Unhover.png')no-repeat center;
					// background-size: cover;

					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -13.2rem -3px;
    				background-size: 561px;
					&:hover{
						background: url('/static/images/hayhar/eBet_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-4{
					// background: url('/static/images/hayhar/EVO_Unhover.png')no-repeat center;
					// background-size: cover;

					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -5rem 4.1px;
					background-size: 440px;
					width: 5rem;

					&:hover{
						background: url('/static/images/hayhar/EVO_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-5{
					// background: url('/static/images/hayhar/GPI_Unhover.png')no-repeat center;
					// background-size: cover;
					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -8.6rem -30px;
    				background-size: 272px;

					&:hover{
						background: url('/static/images/hayhar/GPI_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-6{
					// background: url('/static/images/hayhar/IM_Unhover.png')no-repeat center;
					// background-size: cover;
					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -14.4rem -33px;
    				background-size: 300px;
					&:hover{
						background: url('/static/images/hayhar/IM_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-7{
					// background: url('/static/images/hayhar/JILI_Unhover.png')no-repeat center;
					// background-size: cover;
					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -18.5rem -259px;
    				background-size: 574px;
					&:hover{
						background: url('/static/images/hayhar/JILI_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-8{
					// background: url('/static/images/hayhar/Joker_Unhover.png')no-repeat center;
					// background-size: cover;

					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -0.8rem -119.9px;
    				background-size: 410px;
					&:hover{
						background: url('/static/images/hayhar/Joker_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-9{
					background: url('/static/images/hayhar/KingPoker_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/KingPoker_Hover.png')no-repeat center;
						background-size: cover;
					}
				}

				.suppliers-item-10{
					background: url('/static/images/hayhar/KM_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/KM_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-11{
					// background: url('/static/images/hayhar/Microgaming_Unhover.png')no-repeat center;
					// background-size: cover;

					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -4.3rem -209.9px;
					background-size: 410px;

					&:hover{
						background: url('/static/images/hayhar/Microgaming_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-12{
					// background: url('/static/images/hayhar/PG_Unhover.png')no-repeat center;
					// background-size: cover;

					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -14.9rem -119.9px;
    				background-size: 410px;

					&:hover{
						background: url('/static/images/hayhar/PG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-13{
					// background: url('/static/images/hayhar/Playtech_Unhover.png')no-repeat center;
					// background-size: cover;

					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -22.5rem -252px;
    				background-size: 574px;

					&:hover{
						background: url('/static/images/hayhar/Playtech_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-14{
					background: url('/static/images/hayhar/PNG_Unhover.png')no-repeat center;
					background-size: cover;

					// background: url(/static/images/xuebitu.png) no-repeat center center;
					// background-position: -22.5rem -252px;
    				// background-size: 574px;

					&:hover{
						background: url('/static/images/hayhar/PNG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-15{
					background: url('/static/images/hayhar/PP_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/PP_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-16{
					background: url('/static/images/hayhar/SABA-SEA_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/SABA-SEA_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-17{
					background: url('/static/images/hayhar/SPalace_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/SPalace_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-18{
					background: url('/static/images/hayhar/Spribe_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/Spribe_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-19{
					background: url('/static/images/hayhar/TCGaming_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/TCGaming_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-20{
					background: url('/static/images/hayhar/TFG_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/TFG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-21{
					background: url('/static/images/hayhar/V2_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/V2_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-22{
					background: url('/static/images/hayhar/WMCasino_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/WMCasino_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
			}
		}
		/* 版权、 pay 、安全、责任*/
		.copyright-pay-safe{
			display: flex;
			padding: .5rem;
			background-color: rgba(229,231,235,1);
			box-sizing: border-box;
			justify-content: center;
			h6{
				white-space: nowrap;
			}
			.copyright,.safe,.responsibillity{
				width: 16.666667%;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				&.copyright .icon{
					width: 1.6875rem;
					height: 2.0625rem;
					margin-left: 0.625rem;
					background: url(/static/images/hayhar/first-cagayan.png) no-repeat 0 0;
					background-size: cover;
					margin: 0 .25rem;
					&:hover{
						background: url(/static/images/hayhar/first-cagayan-hover.png) no-repeat 0 0;
						background-size: cover;
					}
				}
				&.safe .icon{
					width: 5.375rem;
					height: 2.0625rem;
					background: url(/static/images/hayhar/iovation.png) no-repeat 0 0;
					background-size: cover;
					margin-left: 0.625rem;
					&:hover{
						background: url(/static/images/hayhar/iovation-hover.png) no-repeat 0 0;
						background-size: cover;
					}
				}
				&.responsibillity>div{
					display: flex;
					flex-wrap: wrap;
				}
				&.responsibillity .icon{
					width: 2.0625rem;
					height: 2.0625rem;
					margin-left: 0.625rem;
					&.icon.icon-1{
						background: url('/static/images/hayhar/game-card.png') no-repeat 0 0;
						background-size: cover;
						&:hover{
							background: url('/static/images/hayhar/game-card-hover.png') no-repeat 0 0;
							background-size: cover;
						}
					}
					&.icon.icon-2{
						background: url('/static/images/hayhar/up-21.png') no-repeat 0 0;
						background-size: cover;
						&:hover{
							background: url('/static/images/hayhar/up-21-hover.png') no-repeat 0 0;
							background-size: cover;
						}
					}
				}
			}


			// 付款方式
			.bank-pay{
				display: flex;
				flex-grow:1;
				align-items: center;
				flex-wrap: wrap;
				.icon{
					width: 4.125rem;
					height: 2.0625rem;
					margin: 0 0.3125rem;
				}
				.icon-1{
					background: url('/static/images/hayhar/easypay.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/easypay-hover.png') no-repeat 0 0;
						background-size: cover;
					}
				}
				.icon-2{
					background: url('/static/images/hayhar/localbank.png') no-repeat 0 0;
					width: 3.6875rem;
					height: 2.0625rem;
					&:hover{
						background: url('/static/images/hayhar/localbank-hover.png') no-repeat 0 0;
					}
				}
				.icon-3{
					background: url('/static/images/hayhar/fastbaht.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/fastbaht-hover.png') no-repeat 0 0;
					}
					width: 5.375rem;
				}
				.icon-4{
					width: 2.4375rem;
					height: 2.0625rem;
					background: url('/static/images/hayhar/line.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/line-hover.png') no-repeat 0 0;
					}
				}
				.icon-5{
					width: 4.1875rem;
					background: url('/static/images/hayhar/cashcard.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/cashcard-hover.png') no-repeat 0 0;
					}
				}
				.icon-6{
					width: 4.5rem;
					background: url('/static/images/hayhar/qrcode.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/qrcode-hover.png') no-repeat 0 0;
					}
				}
			}
		}


		// end-page
		.end-page{
			background-color: rgb(14,165,233);
			padding: 1rem;
			.end-page-list{
				display: flex;
				justify-content: center;
				font-size: .875rem;
				color: #FFF;
				.item{
					margin: 0 1.25rem 0 0;
					cursor: pointer;
				}
			}
			.help-contact{
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 2.725rem;
				.help{
					display: flex;
					align-items: center;
					color: #FFF;
					cursor: pointer;
					img{
						margin-right: 0.5rem;
					}
					span{
						border-right: 1px solid #FFF;
						padding-right: 15px;
					}
				}
				.contact-box{
					display: flex;
					align-items: center;
					margin-left: 15px;

					.item{
						width: 2.5rem;
						height: 33.7px;
						margin-right:15px;
						cursor: pointer;
						&:nth-child(1){
							background: url('/static/images/hayhar/footer-line.png');
							background-size: cover;
							&:hover{
								background: url('/static/images/hayhar/footer-line-hover.png');
								background-size: cover;
							}
						}
					}
					.country{
						width: 2rem;
						height: 1.375rem;
						border-left: 1px solid #FFF;
						padding-left:15px ;
						cursor: auto;
					}
				}
			}
			.end-page-footer-text{
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				p{
					color: #FFF;
					margin-top: 0.9375rem;
					font-size: .725rem;
				}
			}
		}
	}


	// 社交按钮
	.social-web{
		position: fixed;
		transform: translate(50%, -50%);
		top: 48%;
		right: -1%;
		.social-item{
			text-decoration: inherit;
			cursor: pointer;
			display: flex;
			align-items: center;
			background-color: rgb(2,132,199);
			border-top-left-radius: 9999px;
			border-bottom-left-radius: 9999px;
			margin: .5rem 0;
			box-shadow: 0 0 #000 , 0 0 #0000 , 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
			padding:1rem;
			white-space:nowrap;
			color: #FFF;
			width: 100%;
			&:hover{
				transform: translate(-90px,0);
				transition-duration:.3s;
			}
			img{
				height: 2.5rem;
				margin-right: .75rem;
			}
		}
	}

}

@import url('./mobile.less');